import React, { useEffect, useMemo, useState } from 'react'
import { AxisOptions, Chart } from 'react-charts'
import { format as formatTime, parse } from 'date-fns'

import { API } from 'lib/gravy'

import { Layout } from 'components'
import { Breadcrumbs, Card, PageHeader } from 'components/ui'

type MonthlyUsers = {
  date: string
  count: number
}

type Series = {
  label: string
  data: MonthlyUsers[]
}

const Index = () => {
  const [userCount, setUserCount] = useState<string>('--')
  const [userData, setUserData] = useState([])

  useEffect(() => {
    API.get('stats').then((response) => {
      setUserData(response.data.users)
    })
  }, [])

  useEffect(() => {
    API.get('users').then((response) => {
      setUserCount(
        new Intl.NumberFormat('en-US').format(response.headers['total-count'])
      )
    })
  }, [])

  const primaryAxis = useMemo(
    (): AxisOptions<MonthlyUsers> => ({
      getValue: (datum) =>
        formatTime(parse(datum.date, 'yyyy-MM-dd', new Date()), 'MMM')
    }),
    []
  )

  const secondaryAxes = useMemo(
    (): AxisOptions<MonthlyUsers>[] => [
      {
        getValue: (datum) => datum.count
      }
    ],
    []
  )

  const userChartData = useMemo(
    (): Series[] => [
      {
        label: 'Users Created by Month',
        data: userData
      }
    ],
    [userData]
  )

  return (
    <Layout layout="flush">
      <PageHeader
        title="Dashboard"
        breadcrumbs={<Breadcrumbs items={[{ text: 'Dashboard', href: '/' }]} />}
      />

      <div className="p-4">
        <div>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Total Users
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {userCount}
              </dd>
            </div>

            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Another Stat?
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">--</dd>
            </div>

            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Another Stat?
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">--</dd>
            </div>
          </dl>
        </div>
      </div>

      {userData.length > 0 ? (
        <div className="p-4">
          <Card>
            <p className="mb-4 text-sm font-medium text-gray-500">
              New User Registrations
            </p>

            <div className="h-36 md:h-64 lg:h-96">
              <Chart
                options={{
                  defaultColors: ['#6345F9'],
                  data: userChartData,
                  primaryAxis,
                  secondaryAxes
                }}
              />
            </div>
          </Card>
        </div>
      ) : null}
    </Layout>
  )
}

export default Index
